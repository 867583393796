import React, { lazy, Suspense, useEffect, useRef } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLISHABLE_KEY } from "constants/ApiConstant";
import { connect } from "react-redux";
import { signOut } from "redux/actions/Auth";
import { message } from "antd";

const AUTO_LOGOUT_TIME = 15 * 60 * 1000;

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
export const AppViews = (props) => {
  const logoutTimer = useRef(null);
  const resetTimer = () => {
    clearTimeout(logoutTimer.current);
    logoutTimer.current = setTimeout(logout, AUTO_LOGOUT_TIME);
  };

  const logout = () => {
    message.warning("Logging you out as you are inactive!!!");
    props.signOut();
  };

  useEffect(() => {
    const events = ["click", "mousemove", "keypress"];

    const eventHandler = () => {
      resetTimer();
    };

    events.forEach((event) => window.addEventListener(event, eventHandler));

    resetTimer(); // Start timer on mount

    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, eventHandler)
      );
      clearTimeout(logoutTimer.current);
    };
  }, []);

  return (
    <Elements
      options={{ appearance: { theme: "stripe" } }}
      stripe={stripePromise}
    >
      <Suspense fallback={<Loading cover="content" />}>
        <Switch>
          <Route
            path={`${APP_PREFIX_PATH}/dashboards`}
            component={lazy(() => import(`./dashboards`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/sales-pipeline/list`}
            component={lazy(() => import(`./pages/sales-pipeline/list`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/sales-pipeline/add`}
            component={lazy(() => import(`./pages/sales-pipeline/add`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/sales-pipeline/view`}
            component={lazy(() => import(`./pages/sales-pipeline/add`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/sales-pipeline/edit`}
            component={lazy(() => import(`./pages/sales-pipeline/add`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/accounts/list`}
            component={lazy(() => import(`./pages/accounts/list`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/accounts/add`}
            component={lazy(() => import(`./pages/accounts/add`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/accounts/view`}
            component={lazy(() => import(`./pages/accounts/add`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/accounts/edit`}
            component={lazy(() => import(`./pages/accounts/add`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/registrations/list`}
            component={lazy(() => import(`./pages/registrations/list`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/registrations/add`}
            component={lazy(() => import(`./pages/registrations/add`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/registrations/view`}
            component={lazy(() => import(`./pages/registrations/add`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/registrations/edit`}
            component={lazy(() => import(`./pages/registrations/add`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/business-users/list`}
            component={lazy(() => import(`./pages/business-users/list`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/business-users/add`}
            component={lazy(() => import(`./pages/business-users/add`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/business-users/view`}
            component={lazy(() => import(`./pages/business-users/add`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/business-users/edit`}
            component={lazy(() => import(`./pages/business-users/add`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/app-user/list`}
            component={lazy(() => import(`./pages/app-user/list`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/mailings/list`}
            component={lazy(() => import(`./pages/mailing/list`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/sales-billings/list`}
            component={lazy(() => import(`./pages/sales-billings/list`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/sales-billings/transactions`}
            component={lazy(() => import(`./pages/sales-billings/view`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/feedback/list`}
            component={lazy(() => import(`./pages/feedback/list`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/customer-care/list`}
            component={lazy(() => import(`./pages/customer-care/list`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/customer-care/add`}
            component={lazy(() => import(`./pages/customer-care/add`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/contact-requests/list`}
            component={lazy(() => import(`./pages/contact-requests/list`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/contact-requests/add`}
            component={lazy(() => import(`./pages/contact-requests/add`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/contact-requests/view`}
            component={lazy(() => import(`./pages/contact-requests/add`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/contact-requests/edit`}
            component={lazy(() => import(`./pages/contact-requests/add`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/support-requests/list`}
            component={lazy(() => import(`./pages/support-requests/list`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/support-requests/add`}
            component={lazy(() => import(`./pages/support-requests/add`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/support-requests/view`}
            component={lazy(() => import(`./pages/support-requests/add`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/support-requests/edit`}
            component={lazy(() => import(`./pages/support-requests/add`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/company-user/list`}
            component={lazy(() => import(`./pages/company-user/list`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/company-user/add`}
            component={lazy(() => import(`./pages/company-user/add`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/company-user/view`}
            component={lazy(() => import(`./pages/company-user/add`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/company-user/edit`}
            component={lazy(() => import(`./pages/company-user/add`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/business`}
            component={lazy(() => import(`./pages/business`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/messages/list`}
            component={lazy(() => import(`./pages/messages/list`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/messages/add`}
            component={lazy(() => import(`./pages/messages/add`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/messages/view`}
            component={lazy(() => import(`./pages/messages/add`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/messages/edit`}
            component={lazy(() => import(`./pages/messages/add`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/edit-profile`}
            component={lazy(() => import(`./pages/setting/EditProfile`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/help`}
            component={lazy(() => import(`./pages/faq`))}
          />
          <Redirect
            from={`${APP_PREFIX_PATH}`}
            to={`${APP_PREFIX_PATH}/dashboards`}
          />
        </Switch>
      </Suspense>
    </Elements>
  );
};

const mapStateToProps = ({ auth }) => {
  const { userInfo } = auth;
  return { userInfo };
};

export default connect(mapStateToProps, { signOut })(React.memo(AppViews));
