import { DashboardOutlined } from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { BsHeadset } from "react-icons/bs";
import { MdListAlt } from "react-icons/md";
import { FaRegComments, FaUsers, FaUserPlus } from "react-icons/fa";
import { RiFolderUserLine } from "react-icons/ri";
import {
  BiChart,
  BiBriefcase,
  BiUserCheck,
  BiMessageAltDetail,
  BiPhoneCall,
} from "react-icons/bi";
import { AiOutlineMobile, AiOutlineIssuesClose } from "react-icons/ai";
import { VscFeedback } from "react-icons/vsc";
import { ImUserTie } from "react-icons/im";

const dashBoardNavTree = [
  {
    key: "dashboards",
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: "sidenav.Dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "dashboards-default",
        path: `${APP_PREFIX_PATH}/dashboards`,
        title: "sidenav.Dashboard",
        icon: BiChart,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "sales-list",
        path: `${APP_PREFIX_PATH}/sales-pipeline/list`,
        title: "sidenav.Sales",
        icon: RiFolderUserLine,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "accounts-list",
        path: `${APP_PREFIX_PATH}/accounts/list`,
        title: "sidenav.Accounts",
        icon: FaUsers,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "registrations-list",
        path: `${APP_PREFIX_PATH}/registrations/list`,
        title: "sidenav.Registrations",
        icon: FaUserPlus,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "business-list",
        path: `${APP_PREFIX_PATH}/business-users/list`,
        title: "sidenav.BusinessUsers",
        icon: BiUserCheck,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "app-list",
        path: `${APP_PREFIX_PATH}/app-user/list`,
        title: "sidenav.AppUsers",
        icon: AiOutlineMobile,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "mailings-list",
        path: `${APP_PREFIX_PATH}/mailings/list`,
        title: "sidenav.Mailings",
        icon: FaRegComments,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "sales-billing-list",
        path: `${APP_PREFIX_PATH}/sales-billings/list`,
        title: "sidenav.SalesBilling",
        icon: MdListAlt,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "feedback-list",
        path: `${APP_PREFIX_PATH}/feedback/list`,
        title: "sidenav.Feedback",
        icon: VscFeedback,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "business",
        path: `${APP_PREFIX_PATH}/business/list`,
        title: "sidenav.GoToBusiness",
        icon: BiBriefcase,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "customer-support",
        path: `${APP_PREFIX_PATH}/support-requests/list`,
        title: "sidenav.CustomerSupport",
        icon: BsHeadset,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "contact-requests",
        path: `${APP_PREFIX_PATH}/contact-requests/list`,
        title: "sidenav.ContactRequests",
        icon: BiPhoneCall,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "company-user",
        path: `${APP_PREFIX_PATH}/company-user/list`,
        title: "sidenav.CompanyUser",
        icon: ImUserTie,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "messagessss",
        path: `${APP_PREFIX_PATH}/messages/list`,
        title: "sidenav.Messages",
        icon: BiMessageAltDetail,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "help",
        path: `${APP_PREFIX_PATH}/help/list`,
        title: "sidenav.HelpWiki",
        icon: AiOutlineIssuesClose,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
